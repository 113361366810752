import {useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Button} from "antd";
import Tooltip from '../tooltip/tooltip';
import { collection } from "@firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {db} from '../../firebase';

export default function Card({path, doc, tooltipOptions, updateItem, returnItem, bookId, showBookNumber, bookHistory}) {
    const {id, name, borrowed_date, return_date, borrowed_user} = doc;
    const [isVisible, setIsVisible] = useState(false);
    const showVisible = () => {setIsVisible(!isVisible);}
    const query = collection(db, path);
    const [values, loading, error] = useCollectionData(query);

    return (
        <>
            <div className="card-list__item card-list__item--4col card-list__item--full">
                <p className="card-list__title">도서명</p>
                <p className="card-list__value">{name}</p>
            </div>
            <div className="card-list__item card-list__item--4col">
                <p className="card-list__title">대여일</p>
                <p className="card-list__value">{borrowed_date}</p>
            </div>
            <div className="card-list__item card-list__item--4col">
                <p className="card-list__title">대여기한</p>
                <p className="card-list__value">{return_date}</p>
            </div>
            <div className="card-list__item card-list__item--4col">
                <p className="card-list__title">대여자</p>
                <p className="card-list__value">{borrowed_user}</p>
            </div>
            <Button
                className="card-list__more-button"
                onClick={() => {
                    showBookNumber(id)
                    showVisible()
                }}>
                <MoreVertIcon/>
            </Button>
            {isVisible
                ?
                <>
                    <Tooltip
                        borrowHistory={values}
                        tooltipOptions={tooltipOptions}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        updateItem={updateItem}
                        returnItem={returnItem}
                        bookId={bookId}
                        bookHistory={bookHistory}
                    />
                </>
                : <></>
            }
        </>
    )
}