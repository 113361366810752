import './modalCard.scss';

export default function ModalUser({ history }) {
    const {name, borrowed_date, return_date, borrowed_user} = history
    return (
        <>
            <div className="card-wrapper">
                <div className="modal-card modal-card--book-name">
                    <p className="modal-card__title">도서명</p>
                    <p className="modal-card__value modal-card__value--book-name">{name}</p>
                </div>
                <div className="modal-card modal-card--borrowed-date">
                    <p className="modal-card__title">대여일</p>
                    <p className="modal-card__value">{borrowed_date}</p>
                </div>
                <div className="modal-card modal-card--return-date">
                    <p className="modal-card__title">반납일</p>
                    <p className="modal-card__value">{return_date}</p>
                </div>
                <div className="modal-card modal-card--user">
                    <p className="modal-card__title">대여자</p>
                    <p className="modal-card__value">{borrowed_user}</p>
                </div>
            </div>
        </>
    )
}