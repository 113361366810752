import React, {useState, useEffect} from "react";
import {addDoc, collection, doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import dayjs from "dayjs";
import HelpIcon from '@mui/icons-material/Help';
import {Typography, IconButton, Tooltip,} from '@mui/material';
import CardList from "../../components/borrow/cardList";
import DrawerComponent from "../../components/borrow/drawerComponent"
import Back from "../../components/back/back";
import Modal from "../../components/modal/modal";
import ModalCard from "../../components/modal/modalCard";
import ModalUser from "../../components/modal/modalUser";

export default function Borrow({docs, loading, userName, name, subCollection, isAdmin}) {
    const user = userName;
    const tooltipOptions = [
        {id: 1, value: "edit", name: "수정"},
        {id: 2, value: "return", name: "반납완료"},
        {id: 3, value: "history", name: "히스토리"},
    ];
    const [bookId, setBookId] = useState(null);
    const [nameValue, setNameValue] = useState("");
    const [borrowingDate, setBorrowingDate] = useState(new Date());
    const todayDate = dayjs(borrowingDate).format("YYYY.MM.DD");
    const [selectBorrowingDate, setSelectBorrowingDate] = useState();
    const [selectDate, setSelectDate] = useState("");
    const setTodayDate= dayjs(new Date()).format("YYYY.MM.DD");
    const [editSaveDate, setEditSaveDate] = useState();
    const [renewDate, setRenewDate] = useState();
    const [borrowingDivisionValue, setBorrowingDivisionValue] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [selectedCardData, setSelectedCardData] = useState(null);
    const [editDisabled, setEditDisabled] = useState(false);
    const [bookModalOpen, setBookModalOpen] = useState(false);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [borrowHistory, setBorrowHistory] = useState("");
    const [subCollectionHistory, setSubCollectionHistory] = useState("");
    const [period, setPeriod] = useState("")

    const onPeriodChange = (e) => {
        const {value} = e.target
        setPeriod(value)
        openUserModal();
        checkUser();
    }

    const checkUser = () => {
        let tryArray = [];
        setSubCollectionHistory("");
        subCollection.map((data) => {
            if(period ===  data.borrowed_user) {
                tryArray.push(data);
                setSubCollectionHistory(tryArray);
            }
        })
    }

    useEffect(()=> {
        checkUser();
    },[period])

    //책 대여히스트리 모달 열기
    const openBookModal = () => {
        setBookModalOpen(true);
    };
    //책 대여히스트리 모달 닫기
    const closeBookModal = () => {
        setBookModalOpen(false);
    };

    //유저 대여히스트리 모달 열기
    const openUserModal = () => {
        setUserModalOpen(true);
    };

    //유저 대여히스트리 모달 닫기
    const closeUserModal = () => {
        setUserModalOpen(false);
    };

    const addHandle = () => {
        alert(`현재 대여중 도서는 검색되지 않습니다. \n도서 대여시, 대여가능 여부는 '보유도서목록' 페이지에서 확인 가능합니다.`);
        setIsDrawerOpen(true)
    }

    const handleChange = (newValue) => {
        const formatDayJs = dayjs(newValue).format("YYYY.MM.DD")
        setSelectBorrowingDate(formatDayJs)
        setBorrowingDate(newValue);
        borrowingDivisionValue === '기술서적'
            ? setSelectDate(dayjs(newValue).add(30, 'day').format("YYYY.MM.DD"))
            : setSelectDate(dayjs(newValue).add(60, 'day').format("YYYY.MM.DD"))
    };

    // 수정모드일때 반납날짜 계산해주는 함수
    const handleChangeTest = (newValue) => {
        const selectedCard = docs.filter(item => item.id === bookId)
        const formatDayJs = dayjs(newValue).format("YYYY.MM.DD")
        borrowingDivisionValue === selectedCard[0].division
            ? setRenewDate(dayjs(newValue).add(30, 'day').format("YYYY.MM.DD"))
            : setRenewDate(dayjs(newValue).add(60, 'day').format("YYYY.MM.DD"))
        setEditSaveDate({
            borrowed_date: formatDayJs,
            return_date: renewDate,
        });
    };

    const computedValue = (division) => {
        setBorrowingDivisionValue(division);
        division === '기술서적'
            ? setSelectDate(dayjs(borrowingDate).add(30, 'day').format("YYYY.MM.DD"))
            : setSelectDate(dayjs(borrowingDate).add(60, 'day').format("YYYY.MM.DD"))
    };

    // 수정 drawer 오픈하고 데이터 가져오는 함수
    const updateItem = (bookId) => {
        const selectedCard = docs.filter(item => item.id === bookId)
        if (selectedCard[0].borrowed_user === user) {
            setIsEditDrawerOpen(true);
            setIsEditing(true);
            setSelectedCardData(selectedCard[0]);
            setEditSaveDate(selectedCard[0]);
        } else {
            alert('본인의 신청 정보만 수정 가능합니다.')
        }
    };

    // 수정모드에서 submit 눌렀을때 데이터 저장하는 함수
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setEditDisabled(true);
        const docRef = doc(db, "library", bookId);
        await updateDoc(docRef, {
            borrowed_user: user,
            borrowed_date: editSaveDate.borrowed_date,
            return_date: renewDate,
            return_complete: false,
            borrowed: true,
        });
        setIsEditDrawerOpen(!isEditDrawerOpen);
        setIsEditing(false);
        setEditDisabled(false);
    };

    // 수정, 삭제, 반납완료, 구매완료
    const showBookNumber = (id) => {
        setBookId(id);
    };

    // 반납 완료
    const returnItem = async (bookId) => {
        const selectedCard = docs.filter(item => item.id === bookId)
        if (selectedCard[0].borrowed_user === user) {
            if (window.confirm('반납완료 하시겠습니까?')) {
                const docRef = doc(db, 'library', bookId);
                await updateDoc(docRef, {
                    borrowed_user: user,
                    borrowed_date: selectedCard[0].borrowed_date,
                    return_date: selectedCard[0].return_date,
                    return_complete: true,
                    borrowed: false,
                });
                await addDoc(collection(db, `library/${bookId}/borrow_history`), {
                    borrowed_user: user,
                    borrowed_date: selectedCard[0].borrowed_date,
                    return_date: selectedCard[0].return_date,
                    return_complete: true,
                });
            }
        } else {
            alert('본인의 신청 정보만 반납완료 가능합니다.')
        }
    };

    // 대여 히스토리
    const bookHistory = (borrowHistory) => {
        openBookModal();
        setBorrowHistory(borrowHistory);
    };

    return (
        <>
            <div className="inner">
                <Back />
                <div className="inner__wrapper">
                    <div className="tooltip">
                        <Tooltip
                            className="tooltip"
                            enterTouchDelay={0}
                            title={
                                <>
                                    <Typography>1. 기술서적 : 1개월</Typography>
                                    <Typography>2. 비기술서적 : 2개월</Typography>
                                </>
                            }
                        >
                            <IconButton>
                                <HelpIcon color="error"/>
                            </IconButton>
                        </Tooltip>
                        <p className="tooltip__text">회사
                            <span style={{color: '#fff', fontWeight: 'bold'}}> 대여 목록</span> 입니다.<br/>
                        </p>
                    </div>
                    <div className="purchase-desc">
                        도서 대여 시 아래 목록을 추가해주세요.
                    </div>
                    { // 관리자의 경우, 사원의 모든 대여 목록을 확인가능 할 수 있다.
                        isAdmin
                            ?
                                <span>
                                    <div
                                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3px'}}>
                                        <select
                                            style={{color: '#989693'}}
                                            value={period}
                                            onChange={onPeriodChange}
                                        >
                                            {
                                                name.sort((a, b) => a.name > b.name ? 1 : -1).map(period => (
                                                    <option value={period.value}><strong>{period.name}</strong></option>
                                                ))
                                            }
                                        </select><span style={{color: '#989693'}}>님 도서내역</span>
                                    </div>
                                </span>
                            :   <></>
                    }
                    <CardList
                        docs={docs}
                        loading={loading}
                        bookId={bookId}
                        tooltipOptions={tooltipOptions}
                        showBookNumber={showBookNumber}
                        updateItem={updateItem}
                        returnItem={returnItem}
                        bookHistory={bookHistory}
                    />
                    <div className="button-wrapper">
                        <button
                            className="add-button"
                            onClick={addHandle}>add
                        </button>
                    </div>
                </div>
            </div>
            <DrawerComponent
                docs={docs}
                editDisabled={editDisabled}
                setEditDisabled={setEditDisabled}
                userName={userName}
                nameValue={nameValue}
                setNameValue={setNameValue}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                isEditDrawerOpen={isEditDrawerOpen}
                setIsEditDrawerOpen={setIsEditDrawerOpen}
                isEditing={isEditing}
                borrowingDate={borrowingDate}
                todayDate={todayDate}
                selectBorrowingDate={selectBorrowingDate}
                selectDate={selectDate}
                selectedCardData={selectedCardData}
                setSelectedCardData={setSelectedCardData}
                setTodayDate={setTodayDate}
                setBorrowingDate={setBorrowingDate}
                setSelectDate={setSelectDate}
                editSaveDate={editSaveDate}
                computedValue={computedValue}
                handleChange={handleChange}
                handleChangeTest={handleChangeTest}
                handleEditSubmit={handleEditSubmit}
            />
            <Modal open={bookModalOpen} close={closeBookModal} header="대여 히스토리">
                {borrowHistory.length > 0 &&
                    <div>
                        {borrowHistory.map((history) => (
                            <ModalCard history={history} />
                        ))
                        }
                    </div>
                }
                {borrowHistory.length === 0 && <p>대여 히스토리가 없습니다.</p>}
            </Modal>
            <Modal open={userModalOpen} close={closeUserModal} header="유저 대여 히스토리">
                {
                    period && subCollectionHistory.length > 0 &&
                    <div>
                        {subCollectionHistory.map((history) => (
                            <ModalUser history={history}/>
                        ))}
                    </div>
                }
                {subCollectionHistory.length === 0 && <p>유저 대여 히스토리가 없습니다.</p>}
            </Modal>
        </>
    )
}

