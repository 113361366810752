import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './back.scss';

const Back = () => {
    return (
        <Link to="/main">
            <ArrowBackIcon className="icon"/>
        </Link>
    );
};

export default Back;