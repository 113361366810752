import React, {useRef, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from '@mui/icons-material/Help';
import {Box, Drawer, Typography, IconButton, Tooltip, DialogTitle, InputBase, TextField,} from '@mui/material';
import {addDoc, collection, doc, deleteDoc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import CardList from "../../components/purchase/cardList";
import Back from "../../components/back/back";
import Modal from "../../components/modal/modal";

const Purchase = ({docs, loading, userName, isAdmin}) => {
    const user = userName;
    const tooltipOptions = [
        {id: 1, value: "edit", name: "수정"},
        {id: 2, value: "delete", name: "삭제"},
        {id: 3, value: "complete", name: "구매완료"},
    ];
    const [bookId, setBookId] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [selectDate, setSelectDate] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const [editDate, setEditDate] = useState();
    const [selectedCardData, setSelectedCardData] = useState(null);
    const [selectValue, setSelectValue] = useState({address: "본사", division: "기술서적", own: "no"});
    const [disabled, setDisabled] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const todayDate = dayjs(purchaseDate).format("YYYY.MM.DD");
    const setTodayDate = dayjs(new Date()).format("YYYY.MM.DD");
    const nameRef = useRef();
    const authorRef = useRef();
    const publisherRef = useRef();

    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    const handleChange = (newValue) => {
        const formatDayJs = dayjs(newValue).format("YYYY.MM.DD")
        setSelectDate(formatDayJs);
        setEditDate(formatDayJs);
        setPurchaseDate(newValue);
    };

    const handleChangeTest = (newValue) => {
        const formatDayJs = dayjs(newValue).format("YYYY.MM.DD")
        setEditDate(formatDayJs);
    };

    const handleSelectedChange = (e) => {
        const selectedValue = {...selectValue}
        switch (e.target.name) {
            case "address":
                selectedValue.address = e.target.value;
                break;
            case "division":
                selectedValue.division = e.target.value;
                break;
            case "own":
                selectedValue.own = e.target.value;
                break;
            default:
                break;
        }
        setSelectValue(selectedValue);
    };

    // 새로운 도서구매신청 추가
    const sendValue = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const card = await addDoc(collection(db, "library"), {
            name: nameRef.current.value || '-',
            author: authorRef.current.value || '-',
            address: selectValue.address || '-',
            publisher: publisherRef.current.value || '-',
            division: selectValue.division || '-',
            own: selectValue.own || '-',
            apply_date: selectDate || todayDate,
            purchase_user: user,
            new_book: true,
            order_complete: false,
            borrowed: false,
            borrowed_user: '-',
            borrowed_date: '-',
            return_date: '-',
            return_complete: false,
        });
        setIsDrawerOpen(!isDrawerOpen);
        setDisabled(false);
        setPurchaseDate(setTodayDate);
    };

    // 수정 drawer 오픈하고 데이터 가져오는 함수
    const updateItem = (bookId) => {
        const selectedCard = docs.filter(item => item.id === bookId)
        if (selectedCard[0].purchase_user === user) {
            setIsEditDrawerOpen(true);
            setIsEditing(true);
            setSelectedCardData(selectedCard[0]);
            setSelectValue({
                address: selectedCard[0].address,
                division: selectedCard[0].division,
                own: selectedCard[0].own,
            })
        } else {
            alert('본인의 신청 정보만 수정 가능합니다.')
        }
    };

    // 수정모드에서 submit 눌렀을때 데이터 저장하는 함수
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setEditDisabled(true);
        const docRef = doc(db, "library", bookId);
        await updateDoc(docRef, {
            name: selectedCardData.name,
            author: selectedCardData.author,
            address: selectValue.address,
            publisher: selectedCardData.publisher,
            division: selectValue.division,
            own: selectValue.own,
            apply_date: editDate || todayDate,
        })
        setIsEditDrawerOpen(false);
        setIsEditing(false);
        setEditDisabled(false);
    };

    // 수정모드일때 변경된 데이터 값 저장하는 함수
    const handleUpdate = (e) => {
        setSelectedCardData({...selectedCardData, [e.target.name]: e.target.value})
    };

    // 수정모드에서 multiple-selectbox 데이터 값 변경 함수
    const handleEditSelectedChange = (e) => {
        handleSelectedChange(e);
    };

    // 수정, 삭제, 반납완료, 구매완료에 필요
    const showBookNumber = (id) => {
        setBookId(id);
    };

    // 삭제
    const removeItem = async (bookId) => {
        const selectedCard = docs.filter(item => item.id === bookId)
        if (selectedCard[0].purchase_user === user) {
            if (window.confirm('삭제하시겠습니까?')) {
                const docRef = doc(db, 'library', bookId);
                await deleteDoc(docRef);
                alert('삭제 처리 되었습니다.');
            }
        } else {
            alert('본인의 신청 정보만 삭제 가능합니다.')
        }
    };

    // 구매 완료
    const completeItem = async (bookId) => {
        if (isAdmin) {
            if (window.confirm('구매완료 하시겠습니까?')) {
                const docRef = doc(db, 'library', bookId);
                await updateDoc(docRef, {
                    order_complete: true,
                    new_book: false,
                })
            }
        } else {
            alert('관리자만 이용할 수 있는 기능입니다.')
        }

    };

    return (
        <>
            <div className="inner">
                <Back/>
                <div className="inner__wrapper">
                    <div className="purchase-modal">
                        <IconButton onClick={openModal}>
                            <HelpIcon color="error"/>
                        </IconButton>
                        <Modal open={modalOpen} close={closeModal} header="참고해주세요">
                            <ol className="purchase-modal__ol">
                                <li className="purchase-modal__li">
                                    <p className="purchase-modal__text strong">1. 업무관련도서</p>
                                    <p className="purchase-modal__text">- 구매수량 : 제한 없음</p>
                                    <p className="purchase-modal__text">- 중복구매 : 최대 2권 중복 구매 가능 <br/>(이미 보유 중인 도서는 1권만
                                        추가 구매 가능)</p>
                                    <p className="purchase-modal__text">- 대여기한 : 2개월</p>
                                </li>
                                <li className="purchase-modal__li">
                                    <p className="purchase-modal__text strong">2. 일반기술서적</p>
                                    <p className="purchase-modal__text">- 구매수량 : 제한 없음</p>
                                    <p className="purchase-modal__text">- 중복구매 : 불가</p>
                                    <p className="purchase-modal__text">- 대여기한 : 1개월</p>
                                </li>
                                <li className="purchase-modal__li">
                                    <p className="purchase-modal__text strong">3. 비기술서적</p>
                                    <p className="purchase-modal__text">- 구매수량 : 전반기/하반기 각 1권</p>
                                    <p className="purchase-modal__text">- 중복구매 : 가능</p>
                                    <p className="purchase-modal__text">- 대여기한 : 소장 가능</p>
                                </li>
                            </ol>
                        </Modal>
                        <p className="tooltip__text">회사
                            <span style={{color: '#fff', fontWeight: 'bold'}}> 도서 구매 신청</span> 입니다.
                        </p>
                        <div className="tooltip__block"></div>
                    </div>

                    <div className="purchase-desc purchase-desc--landscape">
                        도서 구매를 희망하는 경우 아래 목록에 추가하시고,<br className="mo"/>
                        경영지원팀에 알려주세요.<br/>
                        작성하신 수령지에서 받아보실 수 있도록 구매합니다.
                    </div>
                    <CardList
                        docs={docs}
                        loading={loading}
                        tooltipOptions={tooltipOptions}
                        showBookNumber={showBookNumber}
                        removeItem={removeItem}
                        updateItem={updateItem}
                        completeItem={completeItem}
                        bookId={bookId}
                    />
                    <div className="button-wrapper">
                        <button className="add-button"
                                onClick={() => setIsDrawerOpen(true)}>
                            add
                        </button>
                    </div>
                </div>
            </div>
            {/* add 버튼 눌렀을때 나오는 drawer */}
            <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}
                    sx={{background: 'rgba(25, 24, 23, .9)'}}>
                <Box p={2} width="100%" component="form" sx={{backgroundColor: 'rgba(25,24,23,.9)', padding: '0'}}>
                    <DialogTitle sx={{
                        p: '24px 24px 0',
                        marginBottom: '32px',
                        color: '#fcfbfa',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h6" component="div" sx={{fontSize: '16px'}}>
                            구매신청
                        </Typography>
                        <CloseIcon onClick={() => setIsDrawerOpen(false)} color="white" sx={{
                            '&:hover': {
                                color: '#7ac5a2',
                            },
                        }}/>
                    </DialogTitle>
                    <section className="inner-form">
                        <div className="inner-form__item">
                            <label className="inner-form__label">도서명</label>
                            <InputBase inputRef={nameRef} placeholder="구매하실 도서명을 입력하세요"
                                       className="inner-form__input"/>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">저자</label>
                            <InputBase inputRef={authorRef} placeholder="저자명을 입력하세요"
                                       className="inner-form__input"></InputBase>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">수령지</label>
                            <select
                                className="inner-form__select"
                                name="address"
                                value={selectValue.address}
                                onChange={handleSelectedChange}
                            >
                                <option value="본사">본사</option>
                                <option value="자택">자택</option>
                            </select>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">출판사</label>
                            <InputBase
                                inputRef={publisherRef}
                                placeholder="출판사를 입력하세요"
                                className="inner-form__input">
                            </InputBase>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">구분</label>
                            <select
                                className="inner-form__select"
                                name="division"
                                value={selectValue.division}
                                onChange={handleSelectedChange}
                            >
                                <option value="기술서적">기술서적</option>
                                <option value="비기술서적">비기술서적</option>
                                <option value="업무관련서적">업무관련서적</option>
                            </select>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">소장여부</label>
                            <select
                                className="inner-form__select"
                                name="own"
                                value={selectValue.own}
                                onChange={handleSelectedChange}
                            >
                                <option value="no">NO</option>
                                <option value="yes">YES</option>
                            </select>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">신청일</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="yyyy.MM.dd."
                                    defaul
                                    value={purchaseDate}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">신청자</label>
                            <InputBase
                                className="inner-form__input"
                                placeholder={user}
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "WebkitTextFillColor": "#999693 !important",
                                    },
                                }}
                            >
                            </InputBase>
                        </div>
                        <button type="submit" className="button-submit" onClick={sendValue}
                                disabled={disabled}>{disabled ? 'Sending....' : 'submit'}</button>
                    </section>
                </Box>
            </Drawer>
            {/* 수정 버튼 눌렀을때 나오는 drawer */}
            {isEditing &&
                (
                    <Drawer anchor="bottom" open={isEditDrawerOpen} onClose={() => setIsEditDrawerOpen(false)}
                            sx={{background: 'rgba(25, 24, 23, .9)'}}>
                        <Box
                            p={2}
                            width="100%"
                            component="form"
                            sx={{backgroundColor: 'rgba(25,24,23,.9)', padding: '0'}}
                        >
                            <DialogTitle sx={{
                                p: '24px 24px 0',
                                marginBottom: '32px',
                                color: '#fcfbfa',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <Typography variant="h6" component="div" sx={{fontSize: '16px'}}>
                                    구매신청수정
                                </Typography>
                                <CloseIcon onClick={() => setIsEditDrawerOpen(false)} color="white" sx={{
                                    '&:hover': {
                                        color: '#7ac5a2',
                                    },
                                }}/>
                            </DialogTitle>
                            <section className="inner-form">
                                <div className="inner-form__item">
                                    <label className="inner-form__label">도서명</label>
                                    <InputBase inputRef={nameRef}
                                               value={selectedCardData.name}
                                               placeholder="구매하실 도서명을 입력하세요"
                                               className="inner-form__input"
                                               name="name"
                                               onChange={handleUpdate}
                                    />
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">저자</label>
                                    <InputBase inputRef={authorRef}
                                               value={selectedCardData.author}
                                               className="inner-form__input"
                                               placeholder="저자명을 입력하세요"
                                               name="author"
                                               onChange={handleUpdate}>
                                    </InputBase>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">수령지</label>
                                    <select
                                        className="inner-form__select"
                                        name="address"
                                        value={selectValue.address}
                                        onChange={handleEditSelectedChange}
                                    >
                                        <option value="본사">본사</option>
                                        <option value="자택">자택</option>
                                    </select>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">출판사</label>
                                    <InputBase inputRef={publisherRef}
                                               className="inner-form__input"
                                               value={selectedCardData.publisher}
                                               placeholder="출판사를 입력하세요"
                                               name="publisher"
                                               onChange={handleUpdate}>
                                    </InputBase>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">구분</label>
                                    <select
                                        className="inner-form__select"
                                        name="division"
                                        value={selectValue.division}
                                        onChange={handleEditSelectedChange}>
                                        <option value="기술서적">기술서적</option>
                                        <option value="비기술서적">비기술서적</option>
                                        <option value="업무관련서적">업무관련서적</option>
                                    </select>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">소장여부</label>
                                    <select
                                        className="inner-form__select"
                                        name="own"
                                        value={selectValue.own}
                                        onChange={handleEditSelectedChange}>
                                        <option value="yes">YES</option>
                                        <option value="no">NO</option>
                                    </select>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">신청일</label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            inputFormat="yyyy.MM.dd"
                                            value={editDate}
                                            onChange={handleChangeTest}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">신청자</label>
                                    <InputBase
                                        className="inner-form__input"
                                        placeholder={user}
                                        disabled
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                "WebkitTextFillColor": "#999693 !important",
                                            },
                                        }}
                                    >
                                    </InputBase>
                                </div>
                                <button type="submit" className="button-submit" onClick={handleEditSubmit}
                                        disabled={editDisabled}>{editDisabled ? 'Sending....' : 'submit'}
                                </button>
                            </section>
                        </Box>
                    </Drawer>
                )}
        </>
    )
}
export default Purchase;