import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyCRcOP87S2Zo0DIMT7RX253QCNVCntOGzc",
    authDomain: "dfluid-outlay-56e7c.firebaseapp.com",
    projectId: "dfluid-outlay-56e7c",
    storageBucket: "dfluid-outlay-56e7c.appspot.com",
    messagingSenderId: "723557360831",
    appId: "1:723557360831:web:4fd7a10d9648e37d7ed20a"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


