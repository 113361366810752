import {useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Button} from "antd";
import Tooltip from '../tooltip/tooltip';


export default function Card({doc, tooltipOptions, showBookNumber, removeItem, completeItem, bookId, updateItem}) {
    const {id, name, address, author, publisher, division, own, apply_date, purchase_user} = doc;

    const [isVisible, setIsVisible] = useState(false);
    const showVisible = () => {
        showBookNumber(id);
        setIsVisible(!isVisible);
    }

    return (
        <>
            <div className="card-list__item card-list__item--full">
                <p className="card-list__title">도서명</p>
                <p className="card-list__value">{name}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">저자</p>
                <p className="card-list__value">{author}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">수령지</p>
                <p className="card-list__value">{address}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">출판사</p>
                <p className="card-list__value">{publisher}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">구분</p>
                <p className="card-list__value">{division}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">소장여부</p>
                <p className="card-list__value">{own}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">신청일</p>
                <p className="card-list__value">{apply_date}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">신청자</p>
                <p className="card-list__value">{purchase_user}</p>
            </div>
            <Button
                className="card-list__more-button"
                onClick={() => {
                    showBookNumber(id)
                    showVisible()
                }}>
                <MoreVertIcon/>
            </Button>
            {isVisible
                ?
                <>
                    <Tooltip
                        tooltipOptions={tooltipOptions}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        removeItem={removeItem}
                        updateItem={updateItem}
                        completeItem={completeItem}
                        bookId={bookId}
                    />
                </>
                : <></>
            }
        </>
    )
}