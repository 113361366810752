import React, {useRef, useState} from "react";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Drawer} from '@mui/material';
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {Box, DialogTitle, InputBase, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./drawerComponent.scss"

export default function DrawerComponent({
                                            docs,
                                            userName,
                                            nameValue,
                                            setNameValue,
                                            isDrawerOpen,
                                            setIsDrawerOpen,
                                            isEditDrawerOpen,
                                            setIsEditDrawerOpen,
                                            borrowingDate,
                                            todayDate,
                                            selectBorrowingDate,
                                            computedValue,
                                            handleChange,
                                            handleChangeTest,
                                            selectDate,
                                            setTodayDate,
                                            setBorrowingDate,
                                            setSelectDate,
                                            editSaveDate,
                                            isEditing,
                                            handleEditSubmit,
                                            selectedCardData,
                                            editDisabled,
                                        }) {
    const user = userName;
    const nameRef = useRef();
    const [filteredData, setFilteredData] = useState([]);
    const [searchBook, setSearchBook] = useState();
    const [disabled, setDisabled] = useState(false);

    const sendValue = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const docRef = doc(db, "library", searchBook.id);
        await updateDoc(docRef, {
            borrowed_user: user,
            borrowed_date: selectBorrowingDate || todayDate,
            return_date: selectDate,
            return_complete: false,
            borrowed: true,
        });
        setIsDrawerOpen(!isDrawerOpen);
        setDisabled(false);
        setNameValue("");
        setSelectDate("");
        setBorrowingDate(setTodayDate);
    };

    // 검색 필터
    const handleFilter = (e) => {
        const searchWord = e.target.value;
        setNameValue(searchWord);
        const newFilter = docs.filter((value) => {
            if (value.own === "yes" || value.own === "no" && value.order_complete === false || value.borrowed === true) {
                return ;
            }
            return value.name.toLowerCase().includes(searchWord.toLowerCase());
        })
        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    // 검색시, 관련 키워드 뜨는 UI
    const handleName = (e) => {
        const value = e.currentTarget.innerText;
        filteredData.map(data => {
            if (data.borrowed) {
                alert('이미 대여중인 도서입니다.');
                setNameValue("");
                setSelectDate("");
                setBorrowingDate(setTodayDate);
                setFilteredData([]);
            } else if (data.borrowed === false) {
                if (data.name === value) {
                    setSearchBook(data);
                    setNameValue(value);
                    setFilteredData([]);
                    if (docs.filter(item => item.name === value && computedValue(item.division))) ;
                }
            }
        });
    };

    return (
        <>
            {/* add 버튼 눌렀을때 나오는 drawer */}
            <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}
                    sx={{background: 'rgba(25, 24, 23, .9)'}}>
                <Box p={2} width="100%" component="form" sx={{backgroundColor: 'rgba(25,24,23,.9)', padding: '0'}}>
                    <DialogTitle sx={{
                        p: '24px 24px 0',
                        marginBottom: '32px',
                        color: '#fcfbfa',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h6" component="div" sx={{fontSize: '16px'}}>
                            대여신청
                        </Typography>
                        <CloseIcon onClick={() => setIsDrawerOpen(false)} color="white" sx={{
                            '&:hover': {
                                color: '#7ac5a2',
                            },
                        }}/>
                    </DialogTitle>
                    <section className="inner-form">
                        <div className="inner-form__item">
                            <label className="inner-form__label">도서명</label>
                            <div className="inner-form__search-container">
                                <InputBase
                                    value={nameValue}
                                    inputProps={{'aria-label': '도서명 검색'}}
                                    inputRef={nameRef}
                                    onChange={handleFilter}
                                    placeholder="도서명을 입력해주세요."
                                />
                                {filteredData.length !== 0 && (
                                    <div className="dataResult">
                                        {filteredData.slice(0, 15).map((value) => {
                                            return (
                                                <div className="dataItem">
                                                    <p onClick={handleName}>
                                                        {value.name}
                                                    </p>
                                                </div>
                                            )

                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">대여자</label>
                            <InputBase
                                className="inner-form__input"
                                placeholder={user}
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "WebkitTextFillColor": "#999693 !important",
                                    },
                                }}
                            >
                            </InputBase>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">대여일</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="yyyy.MM.dd"
                                    value={borrowingDate}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">대여기한</label>
                            <InputBase
                                className="inner-form__input"
                                value={selectDate}
                                readOnly={true}
                            />
                        </div>
                        <button type="submit" className="button-submit" onClick={sendValue}
                                disabled={disabled}>{disabled ? 'Sending....' : 'submit'}
                        </button>
                    </section>
                </Box>
            </Drawer>
            {/* 수정 버튼 눌렀을때 나오는 drawer */}
            {isEditing && (
                <Drawer anchor="bottom" open={isEditDrawerOpen} onClose={() => setIsEditDrawerOpen(false)}
                        sx={{background: 'rgba(25, 24, 23, .9)'}}>
                    <Box p={2} width="100%" component="form" sx={{backgroundColor: 'rgba(25,24,23,.9)', padding: '0'}}>
                        <DialogTitle sx={{
                            p: '24px 24px 0',
                            marginBottom: '32px',
                            color: '#fcfbfa',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <Typography variant="h6" component="div" sx={{fontSize: '16px'}}>
                                대여신청수정
                            </Typography>
                            <CloseIcon onClick={() => setIsEditDrawerOpen(false)} color="white" sx={{
                                '&:hover': {
                                    color: '#7ac5a2',
                                },
                            }}/>
                        </DialogTitle>
                        <section className="inner-form">
                            <div className="inner-form__item">
                                <label className="inner-form__label">도서명</label>
                                <div className="inner-form__search-container">
                                    <InputBase
                                        sx={{
                                            "&.MuiInputBase-root": {backgroundColor: '#302f2e'},
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                backgroundColor: '#302f2e',
                                                "WebkitTextFillColor": "#5c5a58",
                                                marginLeft: '0',
                                            },
                                        }}
                                        value={selectedCardData.name}
                                        disabled
                                        name="name"
                                        placeholder="도서명을 입력해주세요."
                                    />
                                </div>
                            </div>
                            <div className="inner-form__item">
                                <label className="inner-form__label">대여자</label>
                                <InputBase
                                    className="inner-form__input"
                                    placeholder={user}
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            "WebkitTextFillColor": "#999693 !important",
                                        },
                                    }}
                                >
                                </InputBase>
                            </div>
                            <div className="inner-form__item">
                                <label className="inner-form__label">대여일</label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="yyyy.MM.dd"
                                        value={editSaveDate.borrowed_date}
                                        onChange={handleChangeTest}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="inner-form__item">
                                <label className="inner-form__label">대여기한</label>
                                <InputBase
                                    className="inner-form__input"
                                    value={editSaveDate.return_date}
                                    readOnly={true}
                                />
                            </div>
                            <button type="submit" className="button-submit" onClick={handleEditSubmit}
                                    disabled={editDisabled}>{editDisabled ? 'Sending....' : 'submit'}</button>
                        </section>
                    </Box>
                </Drawer>
            )
            }
        </>
    )
}