import React, {useState} from 'react';
import {Link} from "react-router-dom";
import './main.scss';

const Main = ({message, changedMessage}) => {
    return (
        <div key={changedMessage}>
            <div className="page main">
                <div className="main__wrapper">
                    <blockquote>
                        <p className="main__blockquote">
                            {message[changedMessage].title}<br/>
                            <span>-{message[changedMessage].author}-</span>
                        </p>
                    </blockquote>
                    <Link to="/possession">
                        <button type="submit" className="main__button">보유도서목록</button>
                    </Link>
                    <Link to="/purchase">
                        <button type="submit" className="main__button">도서구매신청</button>
                    </Link>
                    <Link to="/borrow">
                        <button type="submit" className="main__button">대여목록</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Main;