import './modal.scss';

export default function Modal({open, close, header, children}) {
    return (
        <div className={open ? 'openModal modal' : 'modal'}>
            {open ? (
                <section>
                    <header>
                        <h1>{header}</h1>
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                    </header>
                    <main>
                        {children}
                    </main>
                </section>
            ) : null}
        </div>
    )
}
