import './modalCard.scss';

export default function ModalCard({history}) {
    return (
        <>
            <div className="card-wrapper">
                <div className="modal-card">
                    <p className="modal-card__title">대여일</p>
                    <p className="modal-card__value">{history.borrowed_date}</p>
                </div>
                <div className="modal-card">
                    <p className="modal-card__title">반납일</p>
                    <p className="modal-card__value">{history.return_date}</p>
                </div>
                <div className="modal-card">
                    <p className="modal-card__title">대여자</p>
                    <p className="modal-card__value">{history.borrowed_user}</p>
                </div>
            </div>
        </>
    )
}