import {useState, useEffect} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {db} from './firebase';
import {collection, doc, getDoc, getDocs, collectionGroup, where} from "firebase/firestore";
import {getAuth, signInWithEmailAndPassword, onAuthStateChanged} from 'firebase/auth';
import Header from './components/header/header';
import Main from "./pages/main/main";
import Login from "./pages/login/login";
import Possession from "./pages/possession/possession";
import Purchase from "./pages/purchase/purchase";
import Borrow from "./pages/borrow/borrow";
import { useCollectionData, useCollection } from "react-firebase-hooks/firestore";

export default function App() {
    let navigate = useNavigate();
    const query = collection(db, "library")
    const [values, loading, error] = useCollectionData(query, {idField: "id"});
    const auth = getAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState(false);
    const [authToken, setAuthToken] = useState(null);
    const [userName, setUserName] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [changedMessage, setChangedMessage]=useState(0);

    // 08.01 users
    let userArray = [];
    let testArray = [];
    const [tUserArray, setUserArray] = useState([]);
    const [tTestArray, setTestArray] = useState([]);

    const message =
        [
            {
                id:'1',
                title:'양서는 처음 읽을 때는 새 친구를 얻은 것 같고, \n 전에 정독한 책을 다시 읽을 때는 \n 옛 친구를 만나는 것 같다.',
                author: '골드 스미스'
            },
            {
                id:'2',
                title:'좋은 책을 읽는 것은 과거 \n 몇 세기의 가장 훌륭한 사람들과 \n 이야기를 나누는 것과 같다.',
                author: '르네 데카르트'
            },
            {
                id:'3',
                title:'독서는 완성된 사람을 만들고, \n 담론은 재치있는 사람을 만들며, \n 필기는 정확한 사람을 만든다.',
                author: '베이컨'
            },
            {
                id:'4',
                title:'단 한 권의 책밖에 읽은 적이 없는 \n 인간을 경계하라.',
                author: '디즈레일리'
            },
            {
                id:'5',
                title:'단 한 권의 책밖에 읽은 적이 없는 \n 인간을 경계하라.',
                author: '디즈레일리'
            },
            {
                id:'6',
                title:'보기 드문 지식인을 만났을 때는 \n 그가 어떤 책을 읽는가를 물어보아야 한다.',
                author: '에머슨'
            },
            {
                id:'7',
                title:'인생은 매우 짧고 그 중 조용한 시간은 \n 얼마 안 되므로 그 시간을 가치없는 책을 읽는데 \n 낭비하지 말아야 한다.',
                author: '존 러스킨'
            },
            {
                id:'8',
                title:'친구를 고르는 것처럼 저자를 고르라.',
                author: '로스코몬'
            },
            {
                id:'9',
                title:'당신에게 가장 필요한 책은 당신으로 \n 하여금 가장 많이 생각하게 만드는 책이다.',
                author: '마크 트웨인'
            },
            {
                id:'10',
                title:'생각하지 않고 읽는 것은 \n 씹지 않고 식사하는 것과 같다.',
                author: 'E.버크'
            }
        ];

    const changedRandomMessage = e => {
        const len = message.length;
        setChangedMessage(Math.floor(Math.random() * len));
    };

    // 정직원 목록
    async function fetchData() {
        const initUsers = await getDocs(collection(db, "users"));
        initUsers.forEach((user) => {
            userArray.push({
                name: user.data().name
            })
        })
        setUserArray(userArray);
    }

    // firebase로 부터 받아온 SubcollectionData 목록
    async function fetchSubcollectionData(){
        const querySnapshot = await getDocs(collection(db, "library"));
        querySnapshot.forEach(async (libraryDoc) => {
            const queryTest = await getDocs(collection(db, "library", libraryDoc.id, "borrow_history"));
            queryTest.forEach((doc) => {
                testArray.push({
                    name: libraryDoc.data().name,
                    borrowed_date: doc.data().borrowed_date,
                    return_date: doc.data().return_date,
                    return_complete: doc.data().return_complete,
                    borrowed_user: doc.data().borrowed_user
                })
            })
            setTestArray(testArray);
        });
        console.log('queryTest', tTestArray);
    }

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            const isAdmin = docSnap.data().admin;
            if (user) {
                setUserName(docSnap.data().name)
                setIsAdmin(isAdmin)
            } else {
                console.log("유저 정보가 없습니다!");
            }
        });
        //Private Routes
        let authToken = localStorage.getItem('Auth Token')
        setAuthToken(authToken)
        if (authToken) {
            navigate('/main')
            changedRandomMessage();
        }
        if (!authToken) {
            navigate('/')
        }
        fetchData();
        fetchSubcollectionData();
    }, [])

    //로그인
    const handleLogin = (e) => {
        e.preventDefault();
        const authentication = getAuth();
        signInWithEmailAndPassword(authentication, email, password)
            .then((userCredential) => {
                navigate('/main')
                localStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken)
                setAuthToken(localStorage);
                setLoginError(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('error app :', errorCode, errorMessage);
                setLoginError(true);
            })
        setLoginError(false);
    }

    //로그아웃
    const handleLogout = () => {
        localStorage.removeItem('Auth Token');
        navigate('/')
        setAuthToken(null);
    }

    return (
        <div>
            <Header
                handleLogout={handleLogout}
                userName={userName}
                authToken={authToken}
            />
            <Routes>
                <Route path="/" element={
                    <Login
                        setEmail={setEmail}
                        setPassword={setPassword}
                        loginError={loginError}
                        handleLogin={handleLogin}
                    />}
                />
                <Route path="/main" element={
                    <Main
                        message={message}
                        changedMessage={changedMessage}
                    />}
                />
                <Route path="/possession" element={
                    <Possession
                        docs={values}
                        loading={loading}
                        isAdmin={isAdmin}
                    />}
                />
                <Route path="/purchase" element={
                    <Purchase
                        docs={values}
                        loading={loading}
                        userName={userName}
                        isAdmin={isAdmin}
                    />}
                />
                <Route path="/borrow" element={
                    <Borrow
                        docs={values}
                        loading={loading}
                        userName={userName}
                        name={tUserArray}
                        subCollection={tTestArray}
                        isAdmin={isAdmin}
                    />}
                />
            </Routes>
        </div>
    );
}

