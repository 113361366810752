import React from 'react';
import Card from './card';

const CardList = ({
    docs,
    loading,
    tooltipOptions,
    showBookNumber,
    removeItem,
    completeItem,
    bookId,
    updateItem,
}) => (
    <section className="container">
        {loading && "loading..."}
        <ul className="card-list">
            {docs ? docs.sort((a,b) => a.apply_date < b.apply_date ? 1:-1).map((doc) => (
                <div key={doc.id}>
                    {
                        doc.new_book === true &&
                        <li
                            className={
                                doc.order_complete === false
                                    ? "card-list__wrapper"
                                    : "card-list__wrapper inactive"
                            }
                        >
                            <Card
                                doc={doc}
                                showBookNumber={showBookNumber}
                                tooltipOptions={tooltipOptions}
                                removeItem={removeItem}
                                updateItem={updateItem}
                                completeItem={completeItem}
                                bookId={bookId}
                            />
                        </li>
                    }
                </div>
            )) : <div></div>
            }
        </ul>
    </section>
);
export default CardList;