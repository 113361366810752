import "./login.scss";

const Login = ({setEmail, setPassword, handleLogin, loginError}) => {

        return (
            <>
                <div className="page login">
                    <div className="page__wrapper login__wrapper">
                        <h2 className="logo">
                            <strong>디플루이드</strong> 도서 관리 대장
                        </h2>
                        <h3>Login</h3>
                        <p className="desc">
                            디플루이드 임직원만 접근하실 수 있습니다.
                            <br/>
                            그룹웨어 이메일 주소와 비밀번호를 입력하세요.
                        </p>
                        <form>
                            <input placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)}/>
                            <input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}/>
                            {loginError && <p className="errorMessage animate">
                                이메일 주소와 비밀번호를 다시 확인하세요
                            </p>}
                            <button type="submit" onClick={handleLogin}>Next</button>
                        </form>
                    </div>
                </div>
            </>
        )
    }
;
export default Login;
