import {useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Button} from "antd";
import Tooltip from '../tooltip/tooltip';


export default function Card({doc, tooltipOptions, showBookNumber, removeItem, updateItem, bookId, isAdmin}) {
    const {id, name, author, publisher, division, borrowed} = doc;
    const [isVisible, setIsVisible] = useState(false);
    const showVisible = () => {
        showBookNumber(id);
        setIsVisible(!isVisible);
    }

    return (
        <>
            <div className="card-list__item card-list__item--full">
                <p className="card-list__title">도서명</p>
                <p className="card-list__value">{name}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">저자</p>
                <p className="card-list__value">{author}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">출판사</p>
                <p className="card-list__value">{publisher}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">구분</p>
                <p className="card-list__value">{division}</p>
            </div>
            <div className="card-list__item">
                <p className="card-list__title">대여상태</p>
                {borrowed ? <p className="card-list__value card-list__value--red">대여중</p> : <p className="card-list__value">대여가능</p>}
            </div>
            {isAdmin &&
                <Button
                    className="card-list__more-button"
                    onClick={() => {
                        showBookNumber(id)
                        showVisible()
                    }}>
                    <MoreVertIcon/>
                </Button>
            }
            { isVisible
                ?
                <>
                    <Tooltip
                        tooltipOptions={tooltipOptions}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        removeItem={removeItem}
                        updateItem={updateItem}
                        bookId={bookId}
                    />
                </>
                : <></>
            }
        </>
    )
}