import Logout from "../logout/logout";
import './header.scss';

const Header = ({handleLogout, userName, authToken}) => {

    return (
        <>
            <div className="header" >
                <h2 style={{height: '55px'}}>
                    <strong>디플루이드 </strong> 도서 관리 대장
                </h2>
            </div>
            { authToken &&
                <div className="logout">
                    <Logout
                        handleLogout={handleLogout}
                        userName={userName}
                    />
                </div>
            }
        </>
    )
}
export default Header;