import React, {useRef, useState} from "react";
import {collection, addDoc, doc, deleteDoc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from "@mui/icons-material/Close";
import {Box, Drawer, Typography, IconButton, Tooltip, DialogTitle, InputBase} from '@mui/material';
import CardList from "../../components/possession/cardList";
import Back from "../../components/back/back";

const Possession = ({loading, docs, isAdmin}) => {
    const [bookId, setBookId] = useState("");
    const tooltipOptions = [{id: 1, value: "edit", name: "수정"}, {id: 2, value: "delete", name: "삭제"}];
    const [isEditing, setIsEditing] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [selectValue, setSelectValue] = useState("기술서적");
    const [selectedCardData, setSelectedCardData] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const nameRef = useRef();
    const authorRef = useRef();
    const publisherRef = useRef();

    const handleSelectedValue = (e) => {
        const selectedValue = e.target.value;
        setSelectValue(selectedValue);
    };

    const handleEditSelectedValue = (e) => {
        handleSelectedValue(e);
    };

    //새로운 보유도서 추가
    const sendValue = async (e) => {
        e.preventDefault();
        setDisabled(true);
        await addDoc(collection(db, "library"), {
            name: nameRef.current.value || '-',
            author: authorRef.current.value || '-',
            publisher: publisherRef.current.value || '-',
            division: selectValue || '-',
            new_book: false,
            order_complete: true,
            address: '-',
            own: 'no',
            apply_date: '-',
            purchase_user: '-',
            borrowed: false,
            borrowed_user: '-',
            borrowed_date: '-',
            return_date: '-',
            return_complete: false,
        });
        setIsDrawerOpen(!isDrawerOpen);
        setDisabled(false);
    };

    // 수정 drawer 오픈하고 데이터 가져오는 함수
    const updateItem = (bookId) => {
        const selectedCard = docs.filter(item => item.id === bookId)
        setIsEditDrawerOpen(true);
        setIsEditing(true);
        setSelectedCardData(selectedCard[0]);
        setSelectValue(selectedCard[0].division);
    };

    // 수정모드일때 변경된 데이터 값 저장하는 함수
    const handleUpdate = (e) => {
        setSelectedCardData({...selectedCardData, [e.target.name]: e.target.value})
    };

    // 수정모드에서 submit 눌렀을때 데이터 저장하는 함수
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setEditDisabled(true);
        const docRef = doc(db, "library", bookId);
        const docData = await updateDoc(docRef, {
            name: selectedCardData.name,
            author: selectedCardData.author,
            publisher: selectedCardData.publisher,
            division: selectValue,
        })
        setIsEditDrawerOpen(false);
        setIsEditing(false);
        setSelectValue("기술서적");
        setEditDisabled(false);
    };

    // 수정, 삭제, 반납완료, 구매완료에 필요
    const showBookNumber = (id) => {
        setBookId(id);
    };

    // 삭제
    const removeItem = async (bookId) => {
        if (window.confirm('삭제하시겠습니까?')) {
            const docRef = doc(db, 'library', bookId);
            await deleteDoc(docRef);
            alert('삭제 처리 되었습니다.');
        }
    };

    return (
        <>
            <div className="inner">
                <Back/>
                <div className="inner__wrapper">
                    <div className="tooltip">
                        <Tooltip
                            className="tooltip"
                            enterTouchDelay={0}
                            title={
                                <>
                                    <Typography>
                                        3일 이상 열람 또는 대여 시<br/> 대여목록을 작성해주세요.
                                    </Typography>
                                </>
                            }
                        >
                            <IconButton>
                                <HelpIcon color="error"/>
                            </IconButton>
                        </Tooltip>
                        <p className="tooltip__text">회사
                            <span style={{color: '#fff', fontWeight: 'bold'}}> 보유 중인 도서 목록</span> 입니다.
                        </p>
                    </div>
                    <CardList
                        loading={loading}
                        docs={docs}
                        tooltipOptions={tooltipOptions}
                        showBookNumber={showBookNumber}
                        removeItem={removeItem}
                        updateItem={updateItem}
                        bookId={bookId}
                        isAdmin={isAdmin}
                    />
                    <div className="button-wrapper">
                        {isAdmin &&
                            <button className="add-button" onClick={() => setIsDrawerOpen(true)}>add</button>
                        }
                    </div>
                </div>
            </div>
            {/* add 버튼 눌렀을때 나오는 drawer */}
            <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}
                    sx={{background: 'rgba(25, 24, 23, .9)'}}>
                <Box p={2} width="100%" component="form" sx={{backgroundColor: 'rgba(25,24,23,.9)', padding: '0'}}>
                    <DialogTitle sx={{
                        p: '24px 24px 0',
                        marginBottom: '32px',
                        color: '#fcfbfa',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h6" component="div" sx={{fontSize: '16px'}}>
                            도서추가
                        </Typography>
                        <CloseIcon onClick={() => setIsDrawerOpen(false)} color="white" sx={{
                            '&:hover': {
                                color: '#7ac5a2',
                            },
                        }}/>
                    </DialogTitle>
                    <section className="inner-form">
                        <div className="inner-form__item">
                            <label className="inner-form__label">도서명</label>
                            <InputBase inputRef={nameRef} placeholder="추가하실 도서명을 입력하세요" className="inner-form__input"/>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">저자</label>
                            <InputBase inputRef={authorRef} placeholder="저자명을 입력하세요" className="inner-form__input"></InputBase>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">출판사</label>
                            <InputBase inputRef={publisherRef} placeholder="출판사를 입력하세요" className="inner-form__input"></InputBase>
                        </div>
                        <div className="inner-form__item">
                            <label className="inner-form__label">구분</label>
                            <select
                                className="inner-form__select"
                                name="theme"
                                placeholder="Theme"
                                value={selectValue}
                                onChange={handleSelectedValue}
                            >
                                <option value="기술서적">기술서적</option>
                                <option value="비기술서적">비기술서적</option>
                                <option value="업무관련서적">업무관련서적</option>
                            </select>
                        </div>
                        <button type="submit" className="button-submit" onClick={sendValue}
                                disabled={disabled}>{disabled ? 'Sending....' : 'submit'}</button>
                    </section>
                </Box>
            </Drawer>
            {/* 수정 버튼 눌렀을때 나오는 drawer */}
            {isEditing &&
                (
                    <Drawer anchor="bottom"
                            open={isEditDrawerOpen}
                            onClose={() => setIsEditDrawerOpen(false)}
                            sx={{background: 'rgba(25, 24, 23, .9)'}
                            }>
                        <Box
                            p={2} width="100%"
                            component="form"
                            sx={{backgroundColor: 'rgba(25,24,23,.9)', padding: '0'}}>
                            <DialogTitle sx={{
                                p: '24px 24px 0',
                                marginBottom: '32px',
                                color: '#fcfbfa',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <Typography variant="h6" component="div" sx={{fontSize: '16px'}}>
                                    도서추가수정
                                </Typography>
                                <CloseIcon onClick={() => setIsEditDrawerOpen(false)} color="white" sx={{
                                    '&:hover': {
                                        color: '#7ac5a2',
                                    },
                                }}/>
                            </DialogTitle>
                            <section className="inner-form">
                                <div className="inner-form__item">
                                    <label className="inner-form__label">도서명</label>
                                    <InputBase  inputRef={nameRef}
                                                className="inner-form__input"
                                                name="name"
                                                placeholder="추가하실 도서명을 입력하세요"
                                                value={selectedCardData.name}
                                                onChange={handleUpdate}
                                    />
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">저자</label>
                                    <InputBase  inputRef={authorRef}
                                                className="inner-form__input"
                                                placeholder="저자명을 입력하세요"
                                                value={selectedCardData.author}
                                                name="author"
                                                onChange={handleUpdate}
                                    />
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">출판사</label>
                                    <InputBase
                                        className="inner-form__input"
                                        inputRef={publisherRef}
                                        placeholder="출판사를 입력하세요"
                                        name="publisher"
                                        value={selectedCardData.publisher}
                                        onChange={handleUpdate}
                                    ></InputBase>
                                </div>
                                <div className="inner-form__item">
                                    <label className="inner-form__label">구분</label>
                                    <select
                                        className="inner-form__select"
                                        name="theme"
                                        value={selectValue}
                                        onChange={handleEditSelectedValue}
                                    >
                                        <option value="기술서적">기술 서적</option>
                                        <option value="비기술서적">비기술 서적</option>
                                        <option value="업무관련서적">업무관련서적</option>
                                    </select>
                                </div>
                                <button
                                    type="submit"
                                    className="button-submit"
                                    onClick={handleEditSubmit}
                                    disabled={editDisabled}>{editDisabled ? 'Sending....' : 'submit'}
                                </button>
                            </section>
                        </Box>
                    </Drawer>
                )
            }
        </>
    )
}

export default Possession;