import './tooltip.scss';
import React, {useState} from "react";

const Tooltip = ({borrowHistory, bookHistory, tooltipOptions, isVisible, setIsVisible, removeItem, completeItem, updateItem, returnItem, bookId}) => {

    const OptionHandler = (option) => {
        const target = option.value;
        switch (target) {
            case 'edit':
                modifyBookData(target);
                break;
            case 'delete':
                deleteBook(target);
                break;
            case 'complete':
                completeBook(target);
                break;
            case 'return':
                returnBook(target);
                break;
            case 'history':
                history(target);
                break;
            default:
                alert('다시 선택해주세요.');
        }
    }

    const modifyBookData = (target) => {
        updateItem(bookId);
        setIsVisible(!isVisible);
    }
    const deleteBook = (target) => {
        removeItem(bookId);
        setIsVisible(!isVisible);
    }
    const completeBook = (target) => {
        completeItem(bookId);
        setIsVisible(!isVisible);
    }
    const returnBook = (target) => {
        returnItem(bookId);
        setIsVisible(!isVisible);
    }
    const history = (target) => {
        bookHistory(borrowHistory);
        setIsVisible(!isVisible);
    }

    return (
        <>
            <div className="card-modal">
                {tooltipOptions.map((option) =>
                    <div
                        key={option.id}
                        className="card-modal__wrapper"
                        onClick={() => OptionHandler(option)}>
                        {option.name}
                    </div>
                )}
            </div>
        </>
    )
}

export default Tooltip;