import './logout.scss';

const Logout = ({handleLogout, userName}) => {
    return (
                <span className="logout_wrapper">
                    <strong>{userName}</strong>님 환영합니다.
                    <button onClick={handleLogout}>로그아웃</button>
                </span>
    )
}
export default Logout;