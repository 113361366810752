import React from 'react';
import Card from './card';

const CardList = ({loading, docs, tooltipOptions, showBookNumber, removeItem, updateItem, bookId, isAdmin}) => (
    <section className="container">
        {loading && "loading..." }
        <ul className="card-list">
            {docs ? docs.sort((a,b) => a.name > b.name ? 1:-1).map((doc) => (
                <div key={doc.id}>
                    {doc.order_complete === true &&
                        doc.own === 'no' &&
                        <li className="card-list__wrapper">
                            <Card
                                doc={doc}
                                showBookNumber={showBookNumber}
                                tooltipOptions={tooltipOptions}
                                removeItem={removeItem}
                                updateItem={updateItem}
                                bookId={bookId}
                                isAdmin={isAdmin}
                            />
                        </li>
                    }
                </div>
            )) : <div></div>
            }
        </ul>
    </section>
);
export default CardList;