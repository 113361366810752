import React from 'react';
import Card from './card';

const CardList = ({docs, loading, tooltipOptions, showBookNumber, updateItem, returnItem, bookId, bookHistory}) => (
    <section className="container">
        {loading && "loading..."}
        <ul className="card-list">
            {docs ? docs.sort((a,b) => a.borrowed_date < b.borrowed_date ? 1:-1).map((doc) => (
                <div key={doc.id}>
                    {
                        doc.borrowed === true &&
                        <li
                            className={
                                doc.return_complete === false
                                    ? "card-list__wrapper"
                                    : "card-list__wrapper inactive"
                            }
                        >
                            <Card
                                path={`library/${doc.id}/borrow_history`}
                                doc={doc}
                                showBookNumber={showBookNumber}
                                tooltipOptions={tooltipOptions}
                                updateItem={updateItem}
                                returnItem={returnItem}
                                bookId={bookId}
                                bookHistory={bookHistory}
                            />
                        </li>
                    }
                </div>
            )) : <div></div>
            }
        </ul>
    </section>
);
export default CardList;